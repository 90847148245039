.loginPage {
  background-image: url(../base.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login {
  /* border: 1px solid white; */
  width: 600px;
  height: 500px;
  box-sizing: border-box;
  padding: 100px;
  /* filter: blur(6px); */
  /* border-radius: 20px; */
  z-index: 2;
  position: relative;
}
.login::before {
  backdrop-filter: blur(10px);
  background: linear-gradient(
    33.21deg,
    rgba(191, 12, 120, 0.6) 4.71%,
    rgba(1, 3, 52, 0.6) 84.37%
  );

  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  bottom: 0;
  z-index: -1;
  border-radius: 20px;
}
.loginTitle {
  font-weight: 500;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 40px;
  margin: 0;
  z-index: 100;
}
.form {
  z-index: 100;
  display: flex;
  gap: 30px;
  margin-top: 50px;
  flex-direction: column;
}

.input {
  display: flex;
  align-items: center;
  justify-content: start;
  border: 1px solid rgb(151, 151, 151);
  border-radius: 10px;
  padding: 15px;
  gap: 10px;
  background-color: white;
}
.input input {
  border: none;
  background-color: white;
  width: 90%;
}
.input :focus-visible {
  outline: none;
}
.input input::placeholder {
  color: #bbb5ff;
}
.loginBtn {
  background: linear-gradient(
      272.3deg,
      #010334 -4.66%,
      #231bab 49.85%,
      #c11a82 104.02%
    ),
    linear-gradient(0deg, #ffffff, #ffffff);
  border: none;
  color: white;
  font-size: 20px;
  padding: 20px;
  border-radius: 10px;
}

.pa {
  position: absolute;
  bottom: 10px;
  left: 260px;
}
