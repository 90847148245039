.header {
  width: 100%;
  height: 80px;
  background-color: white;
  color: black;
  display: flex;
  align-items: center;
  box-shadow: 0px -10px 30px #9b025e;
}
.header * {
  margin: 0;
}
.container {
  width: 1000px;
  margin: 0 auto;
}
.logout {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  background-color: transparent;
  border: none;
  box-shadow: 3px 3px 6px black;
  padding: 5px 20px;
  border-radius: 20px;
}
.logout:active {
  box-shadow: inset 3px 3px 6px black;
}
.headerContent {
  display: flex;
  justify-content: space-between;
}
.headerTitle {
  background: linear-gradient(102.81deg, #2219c0 -24.36%, #d0208a 162.68%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.studentMain {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.studentProfile {
  border-radius: 50px;
  width: 600px;
  height: 400px;
  margin-top: 100px;
  background-color: white;
  filter: drop-shadow(5px 5px 10px rgba(13, 0, 84, 0.28));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 50px;
  box-sizing: border-box;
}

.studentimg {
  width: 230px;
  height: 230px;
  background-color: #2219c0;
  border-radius: 50%;
  margin: -35% auto 0;
}
.studentimg img {
  width: 100%;
  border-radius: 50%;
}
#green {
  box-shadow: -1px 1px 42px 25px rgb(9, 229, 9);
  -webkit-box-shadow: -1px 1px 42px 25px rgb(9, 229, 9);
  -moz-box-shadow: -1px 1px 42px 25px rgb(9, 229, 9);
}
#red {
  box-shadow: -1px 1px 42px 25px red;
  -webkit-box-shadow: -1px 1px 42px 25px red;
  -moz-box-shadow: -1px 1px 42px 25px red;
}
#yellow {
  box-shadow: -1px 1px 42px 25px rgb(250, 224, 30);
  -webkit-box-shadow: -1px 1px 42px 25px rgb(250, 224, 30);
  -moz-box-shadow: -1px 1px 42px 25px rgb(250, 224, 30);
}
#white {
  box-shadow: -1px 1px 42px 25px rgb(104, 104, 104);
  -webkit-box-shadow: -1px 1px 42px 25px rgb(104, 104, 104);
  -moz-box-shadow: -1px 1px 42px 25px rgb(104, 104, 104);
}

.btns {
  display: flex;
  justify-content: space-between;
}

.btn {
  width: 30%;
  height: 130px;
  border-radius: 30px;
  border: none;
}
#btnGreen {
  background-color: rgb(9, 229, 9);
}
#btnYellow {
  background-color: rgb(250, 224, 30);
}
#btnRed {
  background-color: red;
}
