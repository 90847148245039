.profile {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 10px;
}
.profile img {
  width: 100%;
  border-radius: 50%;
}
