.studentMain {
  display: flex;
  width: 1000px;
  justify-content: space-around;
  align-items: flex-start;
  margin: 50px auto;
}
.side {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.center {
  display: flex;
  flex-direction: column;
  gap: 100px;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.top {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.centerTop {
  width: 400px;
  gap: 50px;
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
}

.btnReset {
  width: 400px;
  height: 50px;
  font-size: 20px;
  border-radius: 50px;
  box-sizing: border-box;
  border: none;
  box-shadow: 0 3px 1px #7b00b8;
  color: black;
}

.btnReset:active {
  box-shadow: inset 0 3px 1px #7b00b8;
}
